import React from 'react';

function Resume() {
  return (
    <section id="resume" className="resume py-10">
      <div className="container mx-auto">

        <div className="section-title mb-8">
          <h2 className="text-4xl font-bold text-center text-gray-800">Resume</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            <div className="resume-item pb-0 mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Furkan Bayar</h4>
              <p className="text-gray-600"><em>Innovative, creative, and deadline-driven Computer Science Student with 3+ years of experience with Java and 3+ years of Linux development.</em></p>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Toronto, ON</li>
                <li><a href="mailto:bfurkan@my.yorku.ca" className="text-blue-600">bfurkanbayar@gmail.com</a></li>
              </ul>
            </div>

            <h3 className="resume-title text-2xl font-semibold text-gray-700 mb-4">Experience</h3>
            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Government of Ontario | Software Developer</h4>
              <h5 className="text-gray-600">July 2024 - December 2024</h5>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Played a pivotal role in developing a highly functional Asset Management System, utilizing full-stack
                  development skills and Agile methodologies.
                </li>
                <li>Developed the frontend using HTML, CSS, JavaScript, and Tailwind to create an intuitive and
                  responsive user interface. Utilized React to build dynamic components, enhancing user interaction.</li>
                <li>Developed RESTful APIs for efficient data retrieval and manipulation, and designed a PostgreSQL
                  database to manage over 50,000 data entries.</li>
                <li>Leveraged Azure for cloud deployment, code sharing, planning, and assignment management, ensuring
                  seamless integration and optimal performance.</li>
                <li>Coordinated with cross-functional teams to troubleshoot issues, ensure data consistency, and implement
                  best practices in code quality and version control with Git and GitHub.</li>
              </ul>
            </div>

            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">York University | Web Developer (Volunteer) </h4>
              <h5 className="text-gray-600">June 2023 to Feb 2024</h5>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Developed a WordPress CMS for refugee claimants, focusing on security, scalability, and responsiveness
                  to meet performance criteria set by Immigration, Refugees and Citizenship Canada and the Ontario
                  Government.</li>
                <li>Collaborated with team members to develop prioritizing security, scalability, and responsiveness.
                  Ensured alignment with performance criteria.</li>
                <li>Utilized PHP and MySQL to develop a scalable and efficient backend infrastructure, resulting in a 40%
                  increase in the platform’s capacity to handle user registrations, secure data storage.</li>
                <li>Implemented responsive design principles and ensured compliance with web accessibility standards to
                  enhance user experience across various devices and screen sizes.
                </li>
              </ul>
            </div>

            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Proda Information Technology | Software Developer </h4>
              <h5 className="text-gray-600">August 2018 to July 2019</h5>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Optimized server performance by implementing strategic NGINX, Apache, PHP, and MySQL
                  configurations on Ubuntu Cent-OS servers, resulting in a 30 millisecond improvement in response time.</li>
                <li>Automated package updates to bolster server stability and security, directly contributing to a
                  quantifiable 40 millisecond increase in project efficiency.</li>
                <li>Led the development and successful implementation of the company website utilizing NGINX Reverse
                  Proxy technology, ensuring seamless integration and optimal performance.</li>
              </ul>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <div className="resume-item pb-0 mb-8">
              <h4 className="text-xl font-semibold text-gray-900">Technical Skills </h4>
              <h5 className="text-l font-semibold text-gray-700 mt-2"> Programming Languages </h5>
              <ul className="list-disc ml-5 mt-2 text-gray-600" >
                <li> C/C++, C#, Java, JavaScript, TypeScript, HTML, CSS, React, Bash Script, Python, Django</li>
              </ul>
              <h5 className="text-l font-semibold text-gray-700 mt-4"> Tools & Frameworks </h5>
              <ul className="list-disc ml-5 mt-2 text-gray-600" >
                <li>  Node.js, Express.js, Next.js, REST API, PostgreSQL, MySQL, Figma, IBM Cloud,
                  UX/UI, Material UI, NGINX, Git, GitHub, Postman, Azure, Bootstrap</li>
              </ul>
            </div>

            <h3 className="resume-title text-2xl font-semibold text-gray-700 mb-4">Projects</h3>
            <div className="resume-item mb-8">
              <h5 className="text-xl font-semibold text-gray-800">Car Dealership Project | Django, React, Express, MongoDB, Docker</h5>
              <h5 className="text-gray-600">January 2024</h5>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Developed the main Django application, setting up both the front-end and back-end skeletons. Created a
                  user management system with registration, login, and logout functionalities using Django templates.
                </li>
                <li>Built Express endpoints to interact with MongoDB, containerized the application using Docker, and
                  implemented Django Models for car make and model to ensure robust database management.
                </li>
                <li>Developed dynamic React pages for listing dealerships, filtering by state, viewing dealer details, and
                  adding reviews. Deployed a sentiment analyzer on IBM Code Engine, integrating it with the application
                  and creating proxy services for efficient communication</li>
                <li>Set up a CI/CD workflow for linting JavaScript and Python files, deployed the Django application on
                  Kubernetes, and incorporated peer review feedback to add front-end and back-end enhancements,
                  including a new car inventory microservice.</li>
              </ul>
            </div>

            <div className="resume-item mb-8">
              <h5 className="text-xl font-semibold text-gray-800">Human Resource Bot - ConuHacks | Python, Flask, and SQLite, Git, Github REST API</h5>
              <h6 className="text-gray-600">March 2023</h6>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Winner of the GSoft sponsor challenge out of 800 attendees and 140 projects.Engineered an innovative
                  HR bot that streamlines the onboarding process by automating repetitive tasks and providing all
                  information in one place.</li>
                <li>Engineered an innovative HR bot that streamlines the onboarding process by automating repetitive tasks
                  and providing all information in one place.</li>
                <li>Integrated powerful tools such as Google Cloud Natural Language API, Calendar API, and GitHub
                  REST API to provide valuable insights into employee sentiment and improve user experience</li>
              </ul>
            </div>

            <div className="resume-item mb-8">
              <h5 className="text-xl font-semibold text-gray-800">Institute of Transportation Engineers ITE | Java ,Pandas, NumPy, SciPy Git, Figma</h5>
              <h6 className="text-gray-600">May 2022</h6>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Worked as part of a team with 3 group members. Selected as a team lead of the project, organize,
                  manage workflow progress, verified quality assurance, and provided technical support.</li>
                <li>Contributed to the preparation of the wireframe, flowchart over Figma. Used programming skills by
                  Pandas, NumPy, SciPy for data exploration.</li>
                <li>Orchestrated effective communication channels within the team, facilitating regular meetings and status
                  updates to ensure alignment on project goals and timelines, fostering a collaborative and cohesive
                  working environment.</li>
                <li>Conducted comprehensive data analysis and visualization to derive meaningful insights and support
                  decision-making processes.
                </li>
              </ul>
            </div>

            <h3 className="resume-title text-2xl font-semibold text-gray-700 mb-4">Education</h3>
            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Bachelor of Arts - Computer Science</h4>
              <h5 className="text-gray-600">2021 - 2025</h5>
              <p className="text-gray-600"><em>York University, Toronto, ON</em></p>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Founder and President of TUSAYU (Turkish Student Association at York University)</li>
                <li>Experience Venture Award Recipient</li>
                <li>Lassonde Roborama Hackathon Winner - <a href="https://github.com/BFurkan/LassondeRoborama" className="text-blue-600">Link to the project</a></li>
                <li>GSoft's sponsor challenge winner in CONUHACKS VII Hackathon - <a href="https://github.com/BFurkan/HRBot" className="text-blue-600">Link to project</a></li>
                <li>Created Android game "Guess It!" using Java and Firebase</li>
              </ul>
            </div>

            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Bachelor of Engineering - Computer Engineering</h4>
              <h5 className="text-gray-600">2016 - 2019</h5>
              <p className="text-gray-600"><em>Gazi University, Ankara, Turkey</em></p>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Co-President of BMT (Computer Engineering Association)</li>
                <li>IOTURKS Innovator's winner - Developed Arduino Education Interface</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Resume;
