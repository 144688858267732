import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Text-based logo */}
        <a className="text-white font-bold text-2xl" href="/">
          Furkan Bayar
        </a>

        {/* Centered Navigation Links */}
        <div className="flex space-x-6">
          <Link to="/" className="text-white hover:text-yellow-500">Home</Link>
          <Link to="/about" className="text-white hover:text-yellow-500">About</Link>
          <Link to="/resume" className="text-white hover:text-yellow-500">Resume</Link>
          <Link to="/projects" className="text-white hover:text-yellow-500">My Project</Link>
          <Link to="/upslash" className="text-white hover:text-yellow-500">Upslash</Link>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
