import React from 'react';

function ReactPortfolio() {
  return (
    <div className="container mx-auto p-8 max-w-4xl">
      {/* Title */}
      <h1 className="text-5xl font-bold mb-6">Building a React Portfolio</h1>
      {/* Date */}
      <p className="text-gray-500 text-sm mb-8">Published on October 17, 2024</p>

      {/* Introduction */}
      <p className="text-xl leading-relaxed text-gray-700 mb-6">
        A personal portfolio is one of the best ways to showcase your work, skills, and projects over time. In this post, I'll walk you through how I built my portfolio website using React and Tailwind CSS.
      </p>

      {/* Section Title */}
      <h2 className="text-3xl font-semibold mb-4">Why React and Tailwind CSS?</h2>
      {/* Content */}
      <p className="text-lg leading-relaxed text-gray-700 mb-6">
        React simplifies the creation of dynamic, component-based websites, allowing for reusable elements like navigation bars and project showcases. Tailwind CSS is a utility-first CSS framework that streamlines styling, making it easier to implement clean, responsive designs without writing custom CSS from scratch.
      </p>

      {/* Section Title */}
      <h2 className="text-3xl font-semibold mb-4">Adding the Unsplash Gallery with API Integration</h2>
      <p className="text-lg leading-relaxed text-gray-700 mb-6">
        A great addition to any portfolio is a photo gallery. Inspired by <a href="https://ademilter.com/photos" className="text-blue-500 hover:text-blue-700">Adem Ilter's website</a>, I integrated the Unsplash API to display photos. You can explore his <a href="https://github.com/ademilter/homepage" className="text-blue-500 hover:text-blue-700">GitHub repository</a> for further insights.
      </p>
      
      <p className="text-lg leading-relaxed text-gray-700 mb-6">
        By incorporating the Unsplash API, I could dynamically display images based on personal interests or project themes, creating a richer experience for visitors.
      </p>

      {/* Footer */}
      <div className="border-t border-gray-300 mt-8 pt-4">
        <p className="text-sm text-gray-500">Written by Furkan Bayar</p>
      </div>
    </div>
  );
}

export default ReactPortfolio;
