import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Upslash from './pages/Upslash';
import Resume from './pages/Resume';
import NavBar from './components/NavBar';
import ReactPortfolio from './blogs/ReactPortfolio';
import JsClosures from './blogs/JsClosures';
import DeployVercel from './blogs/DeployVercel';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/upslash" element={<Upslash />} />
        <Route path="/resume" element={<Resume/>} />
        <Route path="/writing/react-portfolio" element={<ReactPortfolio />} />
        <Route path="/writing/js-closures" element={<JsClosures />} />
        <Route path="/writing/deploy-vercel" element={<DeployVercel />} />
      </Routes>
    </Router>
  );
}

export default App;
